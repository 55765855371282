import React from "react";

const TestimonialForm = ({
  author,
  setAuthor,
  company,
  setCompany,
  testimonial,
  setTestimonial,
  position,
  setPosition,
}) => {
  return (
    <form>
      <label>Name:</label>
      <br />
      <input
        className="w-100 mb-3"
        type="text"
        value={author}
        onChange={(e) => setAuthor(e.target.value)}
      />
      <label>Company:</label>
      <br />
      <input
        className="w-100 mb-3"
        type="text"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
      />
      <br />
      <label>Position:</label>
      <br />
      <input
        className="w-100 mb-3"
        type="text"
        value={position}
        onChange={(e) => setPosition(e.target.value)}
      />
      <br />
      <label>Testimonial:</label>
      <br />
      <textarea
        className="w-100 mb-3"
        type="text"
        value={testimonial}
        onChange={(e) => setTestimonial(e.target.value)}
      />
    </form>
  );
};

export default TestimonialForm;
