import React, { useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import TestimonialForm from "./TestimonialForm";
import firebase from "../../Firebase";

const TestimonialItem = (props) => {
  const [show, setShow] = useState(false);
  const [company, setCompany] = useState(props.company);
  const [testimonial, setTestimonial] = useState(props.testimonial);
  const [position, setPosition] = useState(props.position);
  const [author, setAuthor] = useState(props.author);
  const db = firebase.firestore();

  const handleClose = () => {
    setShow(false);
    setCompany(props.company);
    setAuthor(props.author);
    setPosition(props.position);
    setTestimonial(props.testimonial);
  };
  const handleShow = () => setShow(true);

  const handleUpdate = async () => {
    await db.collection("testimonials").doc(props.id).update({
      company,
      testimonial,
      position,
      author,
    });
    setShow(false);
  };

  const handleDelete = () => {
    return db.collection("testimonials").doc(props.id).delete();
  };
  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <strong>{company}</strong>
          </Accordion.Header>
          <Accordion.Body>
            <div className="accordion-body">
              <blockquote>"{testimonial}"</blockquote>
              <span>
                -
                <strong>
                  {author}, {position}
                </strong>
              </span>
            </div>
            <div className="row d-flex justify-content-end">
              <div className="col-3">
                <button onClick={handleShow} className="w-100 btn btn-primary">
                  Edit
                </button>
              </div>
              <div className="col-3">
                <button onClick={handleDelete} className="w-100 btn btn-danger">
                  Delete
                </button>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add a testimonial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TestimonialForm
            author={author}
            setAuthor={setAuthor}
            company={company}
            testimonial={testimonial}
            setCompany={setCompany}
            setTestimonial={setTestimonial}
            position={position}
            setPosition={setPosition}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-primary" onClick={handleUpdate}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TestimonialItem;
