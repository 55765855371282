import React from 'react'
import VacationEmployee from '../../components/VacationComponents/VacationEmployee';
import './Vacation.scss';

const Vacation = () => {
    return (
        <div className='vacation-screen'>
            <div className='container'>
                <VacationEmployee/>
            </div>
        </div>
    )
}

export default Vacation
