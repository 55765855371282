import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import DescriptionBlock from "../LayoutElements/DescriptionBlock/DescriptionBlock";
import firebase from "./../../Firebase";
import "firebase/firestore";

import "./AddPosition.scss";

const AddPosition = () => {
  const titleOne = "Job Description";
  const titleTwo = "Requirements";
  const titleThree = "What we offer";
  const titleFour = "Bonus";
  const contact = "Applications should be sent directly to ";
  const [title, setTitle] = useState("");
  const [jobDescription, setjobDescription] = useState("");
  const [whatWeOffer, setwhatWeOffer] = useState("");
  const [requirement, setRequirement] = useState("");
  const [requirements, setRequirements] = useState([]);
  const [isDeleted, setDeleted] = useState(false);
  const [bonusSkills, setBonusSkills] = useState([]);
  const [bonusSkill, setBonusSkill] = useState("");
  const history = useHistory();

  const ad = {
    title: title,
    titleOne: titleOne,
    titleTwo: titleTwo,
    titleThree: requirements.length > 0 ? titleThree : "",
    titleFour: bonusSkills.length > 0 ? titleFour : "",
    jobDescription: jobDescription,
    whatWeOffer: whatWeOffer,
    contact: contact,
    requirements: requirements,
    bonusSkills: bonusSkills,
    active: false,
  };

  const bonusRequirementHandler = (e) => {
    e.preventDefault();
    const bonus = [];
    bonus.push(...bonusSkills, bonusSkill);
    setBonusSkills(bonus);
    setBonusSkill("");
  };

  const addRequestHandler = (e) => {
    e.preventDefault();
    const reqs = [];
    reqs.push(...requirements, requirement);
    setRequirements(reqs);
    setRequirement("");
  };

  useEffect(() => {}, [isDeleted]);

  const createAd = () => {
    const db = firebase.firestore();
    db.collection("job-ads")
      .doc(`${title.replace("/", "")}`)
      .set(ad)
      .then(() => {
        console.log("Ad successfully made!");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="addposition ">
      <div className="container mt-5 mb-5">
        <div className="card">
          <div className="card-body">
            <div className="container mt-5">
              <DescriptionBlock
                isLandingBlock={false}
                title="Add a position"
                center={true}
                header="Propeller Dashboard"
              />
              <div>
                <form className="position-form">
                  <div className="form-group px-3">
                    <label htmlFor="title">Title</label>
                    <input
                      required
                      value={title}
                      type="text"
                      className="form-control"
                      id="title"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <br />
                    <label htmlFor="jobDescription">Job Description</label>
                    <textarea
                      required
                      value={jobDescription}
                      type="text"
                      className="form-control"
                      id="job-description"
                      maxLength="300"
                      onChange={(e) => {
                        setjobDescription(e.target.value);
                      }}
                    />
                    <br />
                    <label htmlFor="requirements">Requirements</label>
                    <input
                      type="text"
                      value={requirement}
                      className="form-control"
                      id="requirements"
                      onChange={(e) => setRequirement(e.target.value)}
                    />
                    <div className="text-center mt-3">
                      <button
                        className="btn btn-primary -round -blackborder  -outlined"
                        onClick={(e) => addRequestHandler(e)}
                      >
                        <strong>Add requirement</strong>
                      </button>
                      <div className="mt-4">
                        {requirements.map((requirement, i) => {
                          return (
                            <div className="row requirement mt-3 ">
                              <div className="col-sm-8">
                                <ul className="requirement-list -decoration  my-auto">
                                  <li key={i} className="requirement-text">
                                    {requirement} {"   "}
                                  </li>
                                </ul>
                              </div>

                              <div className="col-sm-4 my-auto">
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    requirements.splice(i, 1);

                                    setDeleted(!isDeleted);
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <br />
                    <br />
                    <label htmlFor="bonusSkils">Bonus Skills</label>
                    <input
                      value={bonusSkill}
                      type="text"
                      className="form-control"
                      id="bonusSkills"
                      onChange={(e) => setBonusSkill(e.target.value)}
                    />
                    <div className="text-center mt-3">
                      <button
                        className="btn btn-primary -round -blackborder  -outlined"
                        onClick={(e) => bonusRequirementHandler(e)}
                      >
                        <strong>Add skill</strong>
                      </button>
                      <div className="mt-4">
                        {bonusSkills.map((skill, i) => {
                          return (
                            <div className="row requirement mt-3">
                              <div className="col-sm-8">
                                <ul className="bonus-list">
                                  <li key={i}>
                                    {skill} {"  "}
                                  </li>
                                </ul>
                              </div>
                              <div className="col-sm-4">
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    bonusSkills.splice(i, 1);
                                    setDeleted(!isDeleted);
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <br />
                    <label htmlFor="whatWeOffer">What we offer:</label>
                    <textarea
                      value={whatWeOffer}
                      type="text"
                      className="form-control"
                      id="whatWeOffer"
                      onChange={(e) => setwhatWeOffer(e.target.value)}
                    />
                  </div>
                  <div className="text-center mt-4 mb-5">
                    <button
                      className="btn btn-primary -position -outlined -round -blackborder"
                      onClick={() => {
                        createAd();
                        history.push("/dashboard");
                      }}
                    >
                      Publish
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPosition;
