import { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import DescriptionBlock from "../../LayoutElements/DescriptionBlock/DescriptionBlock";
import "./LoginSection.scss";

const LoginSection = () => {
  const { currentUser, signInWithMicrosoft, signInWithGoogle } = useAuth();
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleMicrosoftButton = async (e) => {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await signInWithGoogle();
    } catch {
      setError("Failed to log in with Microsoft.");
    }
    setLoading(false);
  };

  return (
    <div className="container login-section">
      <div className="login-center">
        <DescriptionBlock
          isLandingBlock={true}
          header="Welcome to Propeller dashboard"
          title={
            currentUser
              ? `Hello ${
                  currentUser.email.split(".")[0].charAt(0).toUpperCase() +
                  currentUser.email.split(".")[0].slice(1) + " " +
                  currentUser.email.split(".")[1]?.charAt(0).toUpperCase() +
                  currentUser.email.split(".")[1]?.split("@")[0].substring(1)
                }`
              : "Login to your account"
          }
          description="This page is only to be used by Propeller staff."
          center={true}
          propellerWebPage={currentUser ? "" : "https://propeller.ba/careers"}
        />
        {currentUser ? (
          ""
        ) : (
          <div className="row buttons">
            <div className="col-md-2">
              {error}
              <button
                className="login-button -position -round -blackborder -outlined login-purple"
                onClick={handleMicrosoftButton}
                disabled={isLoading}
              >
                Login
              </button>
            </div>

            {/* <div className="col-md-2">
              <Link to={"/register"}>
                <button className="login-button -position -round -blackborder -outlined-default">
                  Signup
                </button>
              </Link>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginSection;
