import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Switch from "react-switch";
import PropTypes from "prop-types";
import "./Accordion.scss";
import firebase from "../../../Firebase";
import "firebase/firestore";

import upArrow from "../../../assets/images/up.svg";
import downArrow from "../../../assets/images/down.svg";

function Accordion(props) {
  const [isOpen, toggle] = useState(false);
  const [isToggled, setToggle] = useState(props.activeStatus);
  const arrow = isOpen ? upArrow : downArrow;
  const history = useHistory();
  const filteredTitle = props.title.replace("/", "");

  const onDelete = () => {
    const db = firebase.firestore();
    db.collection("job-ads").doc(filteredTitle).delete();
  };

  const onToggle = () => {
    const db = firebase.firestore();
    db.collection("job-ads")
      .doc(filteredTitle)
      .set({
        ...props,
        active: !isToggled,
        activeStatus: !isToggled,
      });
  };

  return (
    <div className="a-item">
      <div onClick={() => toggle(!isOpen)} className="a-header">
        <h4>
          {props.title} <img className="a-arrow" src={arrow} alt="Expand" />
        </h4>
        <br />
        <Switch
          checked={isToggled}
          onChange={() => {
            setToggle(!isToggled);
            onToggle();
          }}
        />
      </div>

      {isOpen && (
        <div className="a-body">
          <h5>{props.titleOne}</h5>
          <br></br>
          <p>{props.jobDescription}</p>
          <h5>{props.titleTwo}</h5>
          <br></br>
          <ul>
            {props.requirements &&
              props.requirements.map((value, key) => (
                <li key={key}>• {value}</li>
              ))}
          </ul>
          {props.bonusSkills.length > 0 ? <h5>{props.titleFour}</h5> : ""}
          <br />
          <ul>
            {props.bonusSkills &&
              props.bonusSkills.map((value, key) => (
                <li key={key}>• {value}</li>
              ))}
          </ul>
          <h5>{props.titleThree}</h5>
          <br></br>
          <p>{props.whatWeOffer}</p>
          <br></br>
          <p>
            {props.contact}
            <a href="mailto:career@propeller.ba ">career@propeller.ba </a>
          </p>
          <br></br>
          <p>
            Ad status: <b>{props.active}</b>
            <br />
            <br />
          </p>

          <div className="d-flex justify-content-center">
            <div className="col-md-3">
              <button
                className="btn btn-info -position -round -outlined -blackborder"
                onClick={() => history.push(`/position/${filteredTitle}`)}
              >
                Edit
              </button>
            </div>
            <div className="col-md-3">
              <button
                onClick={() => onDelete()}
                className="btn btn-danger -position -round -outlined-delete -blackborder"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Accordion;
