import React from 'react'
import { Accordion } from 'react-bootstrap'

const VacationRequest = ({req, isUpdating, currentDate, vacationStartDate, vacationEndDate, updateVacationRequest, setUpdateMode, deleteVacationRequest, setVacationStartDate, setVacationEndDate }) => {
    return (
        <>
            <Accordion key={req.id} defaultActiveKey='0'>
                          <Accordion.Item eventKey='1'>
                              <Accordion.Header className={req.status === "APPROVED" ? "text-green" : req.status === "DENIED" ? "text-red" : ""}>Vacation Request</Accordion.Header>
                              <Accordion.Body>
                                  {isUpdating && isUpdating ? <input min={currentDate}  type='date' value={vacationStartDate} onChange={(e) => setVacationStartDate(e.target.value)}/> : <span><strong>Start date:</strong> {req.vacationStartDate}</span>}
                                  <br/>
                                  {isUpdating && isUpdating ? <input  min={vacationStartDate} type='date' value={vacationEndDate} onChange={(e) => setVacationEndDate(e.target.value)}/> : 
                                  <span><strong>End date:</strong> {req.vacationEndDate}</span>
                                }
                                  <br/>
                                  <span><strong>Status:</strong> {req.status === "APPROVED" ? <span className='text-green'>Approved</span> : req.status === "DECLINED" ? <span className='text-red'>Denied</span> : "Pending"}</span>
                                  {isUpdating ? <div className='row d-flex justify-content-end'>
                                      <div className='col-3'>
                                          <button className=' btn btn-info w-100' onClick={(e) => updateVacationRequest(e ,req.id)}>Save</button>
                                      </div>
                                  </div> : <div className='row d-flex justify-content-end'>
                                      <div className='col-3'>
                                          <button className='w-100 btn btn-info' onClick={() => setUpdateMode(req.vacationStartDate, req.vacationEndDate)}>Update</button>
                                      </div>
                                      <div className='col-3'>
                                          <button className='w-100 btn btn-danger' onClick={() => deleteVacationRequest(req.id)}>Delete</button>
                                      </div>
                                  </div>}
                              </Accordion.Body>
                          </Accordion.Item>
                      </Accordion>
        </>
    )
}

export default VacationRequest
