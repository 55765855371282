import LoginSection from "../components/HomeComponents/LoginSection/LoginSection";

const Home = () => {
  return (
    <div className="main content">
      <div className="container">
        <LoginSection />
      </div>
    </div>
  );
};

export default Home;
