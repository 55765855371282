import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import "./DescriptionBlock.scss";

function DescriptionBlock(props) {
  const landingClass = props.isLandingBlock ? "landing" : "";
  const centerClass = props.center ? "center" : "";
  const darkTheme = props.darkTheme ? "dark" : "";

  return (
    <div
      className={`description-block ${landingClass} ${centerClass} ${darkTheme}`}
    >
      {props.header && <h3>{props.header}</h3>}
      {props.title && <h2>{props.title}</h2>}
      {props.description && <p>{props.description}</p>}
      {props.buttonText && (
        <NavLink exact={true} to={props.navTo} className="link-default">
          {props.buttonText}
        </NavLink>
      )}
      {props.propellerWebPage && (
        <a className="link-default" href={props.propellerWebPage}>
          don't have an account?
        </a>
      )}
    </div>
  );
}

DescriptionBlock.propTypes = {
  navTo: PropTypes.string,
  isLandingBlock: PropTypes.bool,
  center: PropTypes.bool,
  darkTheme: PropTypes.bool,
  header: PropTypes.string,
  title: PropTypes.string,
  // description: PropTypes.string,
  buttonText: PropTypes.string,
  imageSrc: PropTypes.string,
  propellerWebPage: PropTypes.string,
};

DescriptionBlock.defaultProps = {
  isLandingBlock: false,
};

export default DescriptionBlock;
