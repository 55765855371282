import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyADErbYPbwOsCo-psVSGOrocwXmPD7R_qc",
  authDomain: "propeller-web-1f129.firebaseapp.com",
  projectId: "propeller-web-1f129",
  storageBucket: "propeller-web-1f129.appspot.com",
  messagingSenderId: "979335241248",
  appId: "1:979335241248:web:83c6eb77aee0a711a0a645",
  measurementId: "G-FPM36ZSW4L"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
export const auth = firebase.auth();
